export function removeKeepAliveCache() {
  if (this.$vnode && this.$vnode.data.keepAlive && this.$vnode.parent) {
    const tag = this.$vnode.tag;
    const caches = this.$vnode.parent.componentInstance.cache;
    const keys = this.$vnode.parent.componentInstance.keys;
    for (const [key, cache] of Object.entries(caches)) {
      if (cache.tag === tag) {
        if (keys.length > 0 && keys.includes(key)) {
          keys.splice(keys.indexOf(key), 1);
        }
        delete caches[key];
      }
    }
  }
  this.$destroy();
}
