import { apiClient, downloadApiClient } from "@/services/HttpService";

// 根据关键字查询下单机构信息
export function fetchCorporationInfoByKeyword(keyword) {
  return apiClient.get(
    `/corporation/search/keyword-purchaser?keyword=${keyword}`
  );
}

// 根据关键字查询卖方机构
export function fetchSellerCorporationByKeyword(keyword) {
  return apiClient.get(`/corporation/search/keyword-seller?keyword=${keyword}`);
}

// 订单日志
export function fetchOrderLog(orderSn, bizType) {
  return apiClient.get(`/orders/approval-log/${orderSn}?bizType=${bizType}`);
}

// 采购订单审核 - 运营总部
export function purchaseOrderExamineOperation(params) {
  return apiClient.put(`/orders/hq/price-approving`, params);
}

// 待审核订单列表
export function examineOrderList(params) {
  return apiClient.get(
    `/orders/approval?orderSn=${params.orderSn}&startTime=${params.startTime}&endTime=${params.endTime}&orderType=${params.orderType}&orderCategory=${params.orderCategory}&purchaserDistributorId=${params.purchaserDistributorId}&isAudited=${params.isAudited}&factoryId=${params.factoryId}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 查询订单详情
export function fetchOrderDetail(params) {
  return apiClient.get(`/orders/${params.orderSn}/${params.type}`);
}

// 订单审核
export function orderExamine(params) {
  return apiClient.put(`/orders/approving`, params);
}

// 付款日志
export function fetchPayLogList(orderSn) {
  return apiClient.get(`/orders/payment-approval-log/${orderSn}`);
}

// 付款审核
export function payExamine(params) {
  return apiClient.put(`/orders/payment-approval`, params);
}

// 重新发起审核
export function reissueExamine(orderSn) {
  return apiClient.patch(`/orders/${orderSn}/payment-approval/relaunch`);
}

// 查看关联订单
export function checkRelevanceOrder(orderSn) {
  return apiClient.get(`/orders/${orderSn}/related`);
}

// 查询付款结果
export function fetchPayResult(orderSn) {
  return apiClient.get(`/orders/bill/${orderSn}`);
}

// 获取待发货列表
export function fetchToDeliverOrderList(params) {
  return apiClient.get(
    `/orders/deliver?orderSn=${params.orderSn}&orderType=${params.orderType}&orderCategory=${params.orderCategory}&startTime=${params.startTime}&endTime=${params.endTime}&purchaserDistributorId=${params.purchaserDistributorId}&deliverStatus=${params.deliverStatus}&factoryId=${params.factoryId}&consigneeProvinceCode=${params.consigneeProvinceCode}&consigneeCityCode=${params.consigneeCityCode}&consigneeCountyCode=${params.consigneeCountyCode}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 查询未发货的sku信息
export function fetchUnDeliverSkuInfo(orderSn, orderType, bizType) {
  return apiClient.get(
    `/consignment/detail/notConsignment/${orderSn}/${orderType}?bizType=${bizType}`
  );
}

// 原厂发货
export function factoryDeliver(params) {
  return apiClient.post(`/consignment/pc`, params);
}

// 总订单列表
export function fetchAllOrderList(params) {
  return apiClient.get(
    `/orders?orderSn=${params.orderSn}&startTime=${params.startTime}&endTime=${
      params.endTime
    }&orderType=${params.orderType}&orderCategory=${
      params.orderCategory
    }&purchaserDistributorId=${params.purchaserDistributorId}&orderStatus=${
      params.orderStatus
    }&factoryId=${params.factoryId}&consigneeProvinceCode=${
      params.consigneeProvinceCode
    }&consigneeCityCode=${params.consigneeCityCode}&consigneeCountyCode=${
      params.consigneeCountyCode
    }&signStatus=${params.signStatus}&producerDistributorId=${
      params.producerDistributorId
    }&pageNum=${params.pageNum}&pageSize=${
      params.pageSize
    }&replaceFlag=${params.replaceFlag || false}&productId=${params.productId ||
      ""}`
  );
}

// 退货审核列表
export function fetchReturnedOrderExamineList(params) {
  return apiClient.get(
    `/orders/approval?orderSn=${params.orderSn}&purchaseOrderSn=${params.purchaseOrderSn}&isAudited=${params.isAudited}&startTime=${params.startTime}&endTime=${params.endTime}&purchaserDistributorId=${params.purchaserDistributorId}&registeredProvinceCode=${params.registeredProvinceCode}&registeredCityCode=${params.registeredCityCode}&registeredCountyCode=${params.registeredCountyCode}&orderCategory=${params.orderCategory}&factoryId=${params.factoryId}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 退货订单列表
export function fetchReturnOrderList(params) {
  return apiClient.get(
    `/orders?orderSn=${params.orderSn}&orderStatus=${params.orderStatus}&startTime=${params.startTime}&endTime=${params.endTime}&purchaseOrderSn=${params.purchaseOrderSn}&orderCategory=${params.orderCategory}&purchaserDistributorId=${params.purchaserDistributorId}&factoryId=${params.factoryId}&registeredProvinceCode=${params.registeredProvinceCode}&registeredCityCode=${params.registeredCityCode}&registeredCountyCode=${params.registeredCountyCode}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 退回订单 - 待退款列表
export function fetchToRefundedList(params) {
  return apiClient.get(
    `/orders/returned/to-payment?orderSn=${params.orderSn}&purchaseOrderSn=${params.purchaseOrderSn}&startTime=${params.startTime}&endTime=${params.endTime}&purchaserDistributorId=${params.purchaserDistributorId}&isPaid=${params.isPaid}&registeredProvinceCode=${params.registeredProvinceCode}&registeredCityCode=${params.registeredCityCode}&registeredCountyCode=${params.registeredCountyCode}&factoryId=${params.factoryId}&orderCategory=${params.orderCategory}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 换货订单 - 待审核列表
export function fetchChangeOrderExamineList(params) {
  return apiClient.get(
    `/orders/approval?orderSn=${params.orderSn}&purchaseOrderSn=${params.purchaseOrderSn}&startTime=${params.startTime}&endTime=${params.endTime}&orderCategory=${params.orderCategory}&purchaserDistributorId=${params.purchaserDistributorId}&isAudited=${params.isAudited}&factoryId=${params.factoryId}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 换货订单 - 订单列表
export function fetchChangeOrderList(params) {
  return apiClient.get(
    `/orders?orderSn=${params.orderSn}&purchaseOrderSn=${params.purchaseOrderSn}&startTime=${params.startTime}&endTime=${params.endTime}&orderCategory=${params.orderCategory}&purchaserDistributorId=${params.purchaserDistributorId}&orderStatus=${params.orderStatus}&signStatus=${params.signStatus}&factoryId=${params.factoryId}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 换货订单 - 待收货列表
export function toReceiveOrderList(params) {
  return apiClient.get(
    `/orders/receive?orderSn=${params.orderSn}&purchaseOrderSn=${params.purchaseOrderSn}&startTime=${params.startTime}&endTime=${params.endTime}&orderCategory=${params.orderCategory}&purchaserDistributorId=${params.purchaserDistributorId}&receiveStatus=${params.receiveStatus}&factoryId=${params.factoryId}&registeredProvinceCode=${params.registeredProvinceCode}&registeredCityCode=${params.registeredCityCode}&registeredCountyCode=${params.registeredCountyCode}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 根据发货单查询未收货的商品
export function checkUnReceiveSkuByInvoice(consignmentId) {
  return apiClient.get(`/receiving/detail/notReceiving/${consignmentId}`);
}

// 收货
export function receiverSku(params) {
  return apiClient.post(`/receiving`, params);
}

// 换货订单 - 待发货列表
export function toDeliverChangeOrder(params) {
  return apiClient.get(
    `/orders/deliver?orderSn=${params.orderSn}&purchaseOrderSn=${params.purchaseOrderSn}&startTime=${params.startTime}&endTime=${params.endTime}&orderCategory=${params.orderCategory}&purchaserDistributorId=${params.purchaserDistributorId}&deliverStatus=${params.deliverStatus}&factoryId=${params.factoryId}&registeredProvinceCode=${params.registeredProvinceCode}&registeredCityCode=${params.registeredCityCode}&registeredCountyCode=${params.registeredCountyCode}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 运营总部 - 换货订单列表
export function changeOrderListOperation(params) {
  return apiClient.get(
    `/orders?orderSn=${params.orderSn}&purchaseOrderSn=${params.purchaseOrderSn}&startTime=${params.startTime}&endTime=${params.endTime}&orderCategory=${params.orderCategory}&purchaserDistributorId=${params.purchaserDistributorId}&displaceSendCorporationId=${params.displaceSendCorporationId}&orderStatus=${params.orderStatus}&signStatus=${params.signStatus}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 查询发货单
export function checkDeliverBill(orderSn, bizType) {
  return apiClient.get(`/consignment/${orderSn}?bizType=${bizType}`);
}

// 退货订单 - 收货列表
export function fetchToReceiveReturnOrderList(params) {
  return apiClient.get(
    `/orders/receive?orderSn=${params.orderSn}&purchaseOrderSn=${params.purchaseOrderSn}&startTime=${params.startTime}&endTime=${params.endTime}&orderCategory=${params.orderCategory}&purchaserDistributorId=${params.purchaserDistributorId}&receiveStatus=${params.receiveStatus}&factoryId=${params.factoryId}&registeredProvinceCode=${params.registeredProvinceCode}&registeredCityCode=${params.registeredCityCode}&registeredCountyCode=${params.registeredCountyCode}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 原厂退款
export function refundedFactory(orderSn) {
  return apiClient.post(`/orders/returned/${orderSn}/refund`);
}

//查询付款记录列表
export function ordersOfflinePayment(params) {
  return apiClient.get(
    `/offline-payment?pageNum=${params.pageNum}&pageSize=${params.pageSize}${
      params.isPayment ? "&isPayment=" + params.isPayment : ""
    }&endTime=${params.endTime}&startTime=${params.startTime}&auditStatus=${
      params.auditStatus
    }&orderSn=${params.orderSn}&rn=${params.rn}&createStatTime=${
      params.createStatTime ? params.createStatTime : ""
    }&createEndTime=${params.createEndTime ? params.createEndTime : ""}${
      params.paymentDistributorId
        ? "&paymentDistributorId=" + params.paymentDistributorId
        : ""
    }${
      params.receiveDistributorId
        ? "&receiveDistributorId=" + params.receiveDistributorId
        : ""
    }&relTypes=${params.relTypes ? params.relTypes : ""}&isUnLookUnSubmit=${
      params.isUnLookUnSubmit === false ? false : ""
    }&auditStatusList=${params.auditStatusList ? params.auditStatusList : ""}`
  );
}
//查询付款单详情
export function ordersOfflinePaymentGet(rn) {
  return apiClient.get(`/offline-payment/${rn}`);
}
//付款审核
export function ordersOfflinePaymentPut(params) {
  return apiClient.put(`/offline-payment/approving`, params);
}
//创建线下付款信息
export function ordersOfflinePaymentPost(params) {
  return apiClient.post(`/orders/offline-payment`, params);
}
//收款银行卡列表
export function collectionCardList(params) {
  return apiClient.get(
    `/collection-card?pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}
//新增收款银行卡
export function collectionCardAdd(params) {
  return apiClient.post(`/collection-card`, params);
}
//删除收款银行卡
export function collectionCardDel(bindId) {
  return apiClient.delete(`/collection-card/${bindId}`);
}
//收款银行卡信息
export function collectionCardDetail(bindId) {
  return apiClient.get(`/collection-card/${bindId}`);
}
//修改收款银行卡
export function collectionCardPut(bindId, params) {
  return apiClient.put(`/collection-card/${bindId}`, params);
}
//设置默认收款银行卡
export function collectionCardSetDefault(bindId) {
  return apiClient.put(`/collection-card/set-default/${bindId}`);
}
//根据卡号获取银行卡
export function bankcardAccountNumber(accountNumber) {
  return apiClient.get(`/bankcard/${accountNumber}`);
}
//批量导出
export function ordersExport(params) {
  return downloadApiClient.get(
    `/offline-payment/export?rnList=${params.rnList}`
  );
}
// 导入
export function offlinePaymentImport(params) {
  return apiClient.post(`/offline-payment/import`, params);
}
// 修改付款信息
export function ordersOfflinePaymentEdit(params) {
  return apiClient.put(`/offline-payment`, params);
}
// 获取日志列表
export function logsList(params) {
  return apiClient.get(`/logs/${params.type}/${params.relId}`);
}
// 订单导出
export function ordersExports(params) {
  return downloadApiClient.get(
    `/orders/export?orderSn=${params.orderSn}&startTime=${params.startTime}&endTime=${params.endTime}&orderType=${params.orderType}&orderCategory=${params.orderCategory}&purchaserDistributorId=${params.purchaserDistributorId}&orderStatus=${params.orderStatus}&factoryId=${params.factoryId}&consigneeProvinceCode=${params.consigneeProvinceCode}&consigneeCityCode=${params.consigneeCityCode}&consigneeCountyCode=${params.consigneeCountyCode}&signStatus=${params.signStatus}&producerDistributorId=${params.producerDistributorId}&productId=${params.productId}`
  );
}

// 查询商品编码列表
export function getSkuList() {
  return apiClient.get("/sku/enabled?pageNum=1&pageSize=999");
}

// 农行-对账列表
export function orderAgriculturalList(params) {
  return apiClient.get(
    `/offline-payment/settle/list?startTime=${params.startTime}&endTime=${
      params.endTime
    }${
      params.receiveCorporationId
        ? "&receiveCorporationId=" + params.receiveCorporationId
        : ""
    }&pageSize=${params.pageSize}&pageNum=${params.pageNum}&matchStatus=${
      params.matchStatus
    }`
  );
}
// 农行-对账列表
export function orderAgriculturalInfo(params) {
  return apiClient.get(
    `/offline-payment/settle/detail?startTime=${params.startTime}&endTime=${
      params.endTime
    }${
      params.receiveCorporationId
        ? "&receiveCorporationId=" + params.receiveCorporationId
        : ""
    }&pageSize=${params.pageSize}&pageNum=${params.pageNum}&settleId=${
      params.settleId
    }&serial=${params.serial}&orderNo=${
      params.orderNo
    }&paymentCorporationCode=${params.paymentCorporationCode}`
  );
}
// 导入
export function orderPaymentSync(params) {
  return apiClient.post(
    `/offline-payment/settle/refresh/result?settleId=${params.settleId}`
  );
}
