import { removeKeepAliveCache } from "@/components/keepAlive";

export const pageDetailMixins = {
  beforeRouteEnter(to, from, next) {
    if (from.meta && from.meta.fatherName && from.meta.fatherName.length > 0) {
      const fatherNameList = from.meta.fatherName;
      for (let i = 0; i < fatherNameList.length; i++) {
        if (fatherNameList[i] === to.name) {
          next(vm => {
            if (vm.hasChange) {
              vm.hasChange = vm.$store.state.hasChange;
            }
          });
          break;
        }
      }
      next();
    } else {
      next(vm => {
        vm.hasChange = false;
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    const fatherNameList = to.meta.fatherName;
    if (to.meta && to.meta.fatherName && to.meta.fatherName.length > 0) {
      let flag = false;
      for (let i = 0; i < fatherNameList.length; i++) {
        if (fatherNameList[i] === this.$route.name) {
          flag = true;
          this.$route.meta.keepAlive = true;
          break;
        }
      }
      if (!flag) {
        removeKeepAliveCache.call(this);
        this.$route.meta.keepAlive = true;
      }
    } else {
      removeKeepAliveCache.call(this);
      this.$route.meta.keepAlive = true;
    }
    next();
  }
};
